<template>
  <div class="integral" v-html="info.body" style="padding: 15px"></div>
</template>

<script>


export default {
  name: "index",
  data() {
    return {
      info:{}
    }
  },
  created() {
    this.getinfo()
  },
  mounted() {

  },
  methods: {
    getinfo(){
      let url = this.$api.integral
      this.$http.get(url, true).then(res => {
        if (res.data.success){
          this.info = res.data.data
        }
      })
    }
  },
  watch: {

  },
  computed: {

  },
  components: {

  }
}
</script>

<style lang="scss" scoped>

</style>
